<template>
  <!-- 关于我们 -->
  <div class="zz_aboutuser2">
    <div
      class="current-nav centerText"
      v-if="Object.keys(gyData).length != 0"
      style="height: 565px"
      :style="{
        background: `url(${gyData.gywmImg}) 100%/100% no-repeat`
      }"
    ></div>
    <div class="all_no_data" v-else>
      <i class="el-icon-loading"></i>
      <p>数据正在加载</p>
    </div>
    <div class="zz_aboutuser2_nav">
      <div class="current-title">
        <span>{{ gyData.gywmTitle }}</span>
      </div>
      <div class="nav_container" v-html="gyData.gywmHtml"></div>
    </div>
    <div class="zz_aboutuser2_nav">
      <div class="current-title">
        <span>{{ cfsjData.cfsj }}</span>
      </div>
      <ul class="current-cfsj">
        <li v-for="(el, i) in cfsjData.cfsjList" :key="i" class="zz_all_img">
          <img :src="el.value" alt="" style="width:100%" />
          <p>{{ el.key }}</p>
        </li>
      </ul>
    </div>
    <div class="zz_aboutuser2_nav">
      <div class="current-title">
        <span>{{ ryzzData.ryzz }}</span>
      </div>
      <ul class="current-ryzz">
        <li v-for="(el, i) in ryzzData.ryzzImg" :key="i">
          <img :src="el" alt="" style="width:100%" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getPageMsg } from '@/api/api'
export default {
  components: {},
  data () {
    return {
      gyData: {},
      cfsjData: {},
      ryzzData:{}
    }
  },
  methods: {
    getData () {
      let that = this
      let id = sessionStorage.getItem(that.changeData() + 'id')
      getPageMsg(id).then(res => {
        if (res.data.code == 1) {
          this.gyData = res.data.data.gywm
          this.cfsjData = res.data.data.cfsj
          this.ryzzData = res.data.data.ryzz
        }
      })
    }
  },
  mounted () {
    this.getData()
  }
}
</script>

<style lang="less" scoped>
@import url('~@/assets/css/mixins.less'); //混合
.zz_aboutuser2 {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .zz_aboutuser2_nav {
    background: #f2f2f2;
    .nav_container {
      width: 1100px;
      background-color: #fff;
      padding: 50px;
      p {
        width: 100%;
        text-align: center;
        line-height: 2;
        font-size: 14px;
        img{
          max-width: 100%;
        }
      }
    }
    .nav_youshi {
      display: flex;
      width: 1100px;
      margin: 0 auto;
      justify-content: space-between;
      background-color: #fff;
      padding: 50px;
      li {
        width: 20%;
        text-align: center;
        color: #000;
        margin: 0 1.5%;
        i {
          display: inline-block;
          width: 100px;
          height: 100px;
          margin: 4% auto;
          background-position-x: center !important;
        }
        p {
          line-height: 1.4;
        }
      }
    }
    .current-cfsj {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 86%;
      margin: 0 auto;
      li {
        width: 25%;
        border: 1px solid #dfdfdf;
        box-sizing: border-box;
        padding: 20px;
        p{
          margin-top: 20px;
          text-align: center;
        }
      }
      li:hover{
        border-color: #C2192E;
        color: #C2192E;
      }
    }
    .current-ryzz {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 86%;
      margin: 0 auto;
      li {
        width: 23%;
        p{
          margin-top: 20px;
          text-align: center;
        }
      }
    }
  }
  .zz_aboutuser2_nav:last-child {
    padding: 0 0 80px;
  }
}
.nav_container {
  margin: 0 auto;
  line-height: 2;
  /deep/ img{
    margin: 0 auto;
    display: block;
  }
}
</style>
